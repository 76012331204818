// hooks
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

// utils
import TimeFormatter from "../../utils/TimeFormatter";
import ActiveStatus from "../../utils/activeStatus";
import UserApi from "../../api/UserApi";
import { Password } from "primereact/password";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import { Dropdown } from "primereact/dropdown";
import { useDebounce } from "use-debounce";

const Users = ({ permissions }) => {
  let data_limit = 10;
  const navigate = useNavigate();

  // state
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });
  const [globalFilter, setGlobalFilter] = useState("");
  const [queryValue] = useDebounce(globalFilter, 1000);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectActiveStatus, setSelectActiveStatus] = useState();

  // api calling
  // const { data: usersData, isLoading, isSuccess, isError, refetch } = useGetUsers();
  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery(
    {
      queryKey: ["users", page, queryValue, selectActiveStatus],
      queryFn: () => getHandler(page),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await UserApi.Add(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setShowCreateModal(false);
        reset({});
        toast.success("User Created!", { duration: 4000 });
      } else {
        refetch();
        setShowCreateModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await UserApi.Delete(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("User Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const { register, handleSubmit, control, formState, reset } = useForm();

  // functions
  const getHandler = async () => {
    try {
      let query = {
        limit: data_limit,
        page: page,
        sort_at: "DESC",
        sort_by: "created_at",
      };

      if (queryValue) {
        query.query = queryValue;
      }

      if (selectActiveStatus) {
        query.active_status = selectActiveStatus;
      }

      const res = await Api().get("/user", { params: query });
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const create = (data) => {
    data.organization_id = process.env.REACT_APP_ORGANIZATION_ID;
    crateMutate({ ...data, user_level: "admin", active_status: data.active_status ? 1 : 0 });
  };

  const createModalHandler = () => {
    setShowCreateModal(true);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      ids: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.ids.push(selectItems[i].id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      ids: [data.id],
    };
    deleteMutate(payload);
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={createModalHandler} />
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => navigate(`/dashboard/users/update/${rowData.id}`)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    let status = rowData.active_status ? "instock" : "outofstock";
    return <span className={`product-badge status-${status}`}>{ActiveStatus(rowData.active_status)}</span>;
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage users</h5>
        <div className="flex flex-column md:flex-row" style={{ gap: "14px" }}>
          <span className="block mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </span>
          <Dropdown
            value={selectActiveStatus}
            onChange={(e) => setSelectActiveStatus(e.value)}
            optionLabel="label"
            optionValue="value"
            options={[
              { label: "Active", value: 1 },
              { label: "Inactive", value: 0 },
            ]}
            placeholder="Select Active Status"
            className="w-full md:w-14rem"
          />
        </div>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  // set page to 1 if gobal filter triggered
  useEffect(() => {
    if (globalFilter.length) {
      setPage(1);
    }

    if (selectActiveStatus) {
      setPage(1);
    }
  }, [globalFilter, selectActiveStatus]);

  return (
    <>
      <div className="grid crud-demo ">
        <div className="col-12  mx-auto">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={userData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="id"
              paginator
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              emptyMessage="No Data Found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="name" header="Name" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="email" header="E-Mail" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="cookie.allow" body={(d) => (d?.cookie?.allow ? d?.cookie?.allow : "-")} header="Cookies consent" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column body={statusBodyTemplate} field="active_status" header="Status" sortable headerStyle={{ width: "10%", minWidth: "5rem" }}></Column>
              <Column field="role.name" header="Role" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {/* <Column field="updated_at" header="Updated at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column> */}
              <Column field="created_at" header="Created at" body={(d) => TimeFormatter(d.created_at)} sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} header="Create User" modal className="p-fluid modal-container" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText placeholder="User Name" {...register("name", { required: true })} id="name" type="text" className={formState.errors.name && "p-invalid"} />
            {formState.errors.name && (
              <small id="name-help" className="p-error block">
                Field Required
              </small>
            )}
          </div>

          <div className="field">
            <label htmlFor="email">Email</label>
            <InputText placeholder="Email address" {...register("email", { required: true })} id="name" type="text" className={formState.errors.email && "p-invalid"} />
            {formState.errors.email && (
              <small id="name-help" className="p-error block">
                Field Required
              </small>
            )}
          </div>

          <div className="field">
            <label htmlFor="password">Password</label>
            <Controller
              rules={{ required: true }}
              control={control}
              name="password"
              render={({ field }) => <Password value={field.value} autoComplete="current-password" placeholder="input password" onChange={(e) => field.onChange(e)} toggleMask feedback={false} className="w-full" inputClassName={classNames("w-full", { "p-invalid": formState.errors.password })} />}
            />
            {formState.errors.password && (
              <small id="name" className="p-error block pt-1">
                Field Required
              </small>
            )}
          </div>

          <div className="field">
            <label htmlFor="phone_number">Phone Number</label>
            <InputText placeholder="Ex : 08522.." {...register("phone_number", { required: true })} id="name" type="text" className={formState.errors.phone_number && "p-invalid"} />
            {formState.errors.phone_number && (
              <small id="name-help" className="p-error block">
                Field Required
              </small>
            )}
          </div>
          <div className="field">
            <label className="block" htmlFor="active_status">
              Active status
            </label>
            <Controller defaultValue={false} control={control} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected users?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Users, comparisonFn);
