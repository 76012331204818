import React from "react";
import { Toaster } from "react-hot-toast";

export const CustomToast = () => {
  return (
    <Toaster
      toastOptions={{
        success: {
          duration: 3500,
          position: "top-center",
          style: {
            backgroundColor: "#fff",
            border: "2px solid #48B9C7",
            overflow: "hidden",
            color: "#48B9C7",
            fontWeight: "700",
            fontSize: "12px",
            textTransform: "capitalize",
          },
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#48B9C7",
            secondary: "#fff",
            borderRadius: "9999px",
          },
        },
        error: {
          duration: 3500,
          position: "top-center",
          style: {
            backgroundColor: "#fff",
            border: "2px solid #E72E4A",
            color: "#E72E4A",
            fontWeight: "700",
            fontSize: "12px",
            textTransform: "capitalize",
          },
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#E72E4A",
            secondary: "#fff",
          },
        },
      }}
    />
  );
};

export default CustomToast;
