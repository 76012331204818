// hooks
import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";
// import { DevTool } from "@hookform/devtools";

// conponents
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

// config
import { InputNumber } from "primereact/inputnumber";

// api related
import Api from "../../api/Api";

const variantTypeOptions = [
  { value: "Color", label: "Color" },
  { value: "Size", label: "Size" },
];

const ProductVariantUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [variantDeleteIds, setVariantDeleteIds] = useState([]);
  const [isLoadingDeleteVariant, setIsLoadingDeleteVariant] = useState(false);

  // hooks
  const { control, register, handleSubmit, watch, reset, getValues } = useForm();
  const variant_name_watch = watch("name");
  const { fields, remove, append } = useFieldArray({
    control,
    name: "variant_details",
  });

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/product-variant/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/product-variants");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = async (data) => {
    try {
      const payload_delete_variant = {
        ids: variantDeleteIds,
      };

      // call delete when variant deleted
      if (variantDeleteIds.length) {
        setIsLoadingDeleteVariant(true);
        const response_delete_variants = await Api().delete("/product-variant/detail", { data: payload_delete_variant });

        if (response_delete_variants.data.status !== 200) {
          throw new Error(response_delete_variants.data.message);
        }
        setIsLoadingDeleteVariant(false);
      }

      crateMutate({ id: id, ...data });
    } catch (error) {
      setIsLoadingDeleteVariant(false);
      toast.error(error.message);
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Variants
          </h4>
        </div>
      </React.Fragment>
    );
  };

  // lifecycle
  useEffect(() => {
    const getProductVariantDetails = async () => {
      try {
        const response = await Api().get("/product-variant/detail/" + id);

        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }
        reset(response.data.data);
      } catch (error) {
        toast.error("Failed to get variant details");
      }
    };

    getProductVariantDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card col-12 mx-auto" style={{ borderRadius: 0 }}>
        <Toolbar className="mb-4" left={leftToolbar} />
        <div className="p-fluid formgrid grid">
          <div className="field flex col-12 p-0">
            <div className="field col-12">
              <label htmlFor="name">Variant Type : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="name"
                  render={({ field }) => (
                    <Dropdown
                      inputRef={field.ref}
                      optionLabel="label"
                      optionValue="value"
                      value={field.value}
                      onBlur={field.onBlur}
                      options={variantTypeOptions}
                      className="w-full"
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      placeholder="Select Variant"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="field flex col-12 p-0">
            <div className="field col-12">
              <label htmlFor="name">Order : </label>
              <div className="w-full">
                <Controller control={control} name="order" render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
              </div>
            </div>
          </div>
        </div>
        {fields.map((item, index) => (
          <div key={item.id}>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Variant Name :</label>
                <InputText placeholder="Input name" {...register(`variant_details.${index}.name`)} id="name" type="text" />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="user">Variant Color (Hex) :</label>
                <InputText disabled={variant_name_watch !== "Color"} placeholder="Input color" {...register(`variant_details.${index}.color`)} id="route" type="text" />
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="order">Variant Order :</label>
                <Controller defaultValue="" control={control} name={`variant_details.${index}.order`} render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
              </div>
              <div className="field col-12 md:col-1">
                <label className="block"></label> <br />
                <Button
                  icon="pi pi-times block"
                  onClick={() => {
                    const current_val = getValues().variant_details;
                    if (current_val[index]?.id) setVariantDeleteIds((prev) => [...prev, current_val[index].id]);
                    remove(index);
                  }}
                  className=" p-button-danger mr-4"
                />
              </div>
            </div>
          </div>
        ))}
        <section>
          <Button type="button" label="Add Field" onClick={() => append({})} className=" p-button-primary mr-4" />
        </section>
        <div className="flex justify-content-center mt-4">
          <Button label="Save" loading={createLoading || isLoadingDeleteVariant} className=" p-button-primary mr-4" />
          <Link to="/dashboard/product-variants">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(ProductVariantUpdate, comparisonFn);
