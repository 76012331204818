// hooks
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
const typeOption = [
  { label: "TEXT", value: 0 },
  { label: "IMAGE", value: 1 },
];
const groupType = [
  { label: "SKIN", value: 0 },
  { label: "HAIR", value: 1 },
];

const Quiz = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // api calling
  const {
    isLoading,
    data: quizData,
    refetch,
  } = useQuery(
    "quiz",
    async () => {
      try {
        const res = await Api().get("/quiz?page=1&limit=999999", {
          headers: {
            organization: process.env.REACT_APP_ORGANIZATION_ID,
          },
        });

        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        return res.data.data;
      } catch (error) {
        toast.error(error.message);
      }
    },
    { initialData: [] }
  );

  const { isLoading: quizOptionDataIsLoading, data: quizOptionData } = useQuery(
    "quiz-options",
    async () => {
      try {
        const res = await Api().get("/quiz-choice?page=1&limit=999999&sort_by=id&sort_type=DESC");

        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        return res.data.data;
      } catch (error) {
        toast.error(error.message);
      }
    },
    { initialData: [] }
  );

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("quiz", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        refetch();
        reset({});
        setShowCreateModal(false);
        toast.success("Quiz Created!", { duration: 4000 });
      } else {
        refetch();
        setShowCreateModal(false);
        toast.error(response.data.err, { duration: 5000 });
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/quiz/edit", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        updateForm.reset({});
        setShowEditModal(false);
        toast.success("Quiz Updated!", { duration: 4000 });
      } else {
        refetch();
        setShowEditModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/quiz", { data }), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Quiz Deleted!", { duration: 4000 });
      } else {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // react hook
  const { reset, handleSubmit, register, control, watch } = useForm();
  const updateForm = useForm();

  const create_is_multiple_choice_watch = watch("is_multiple");
  const update_is_multiple_choice_watch = updateForm.watch("is_multiple");

  // functions
  const create = (data) => {
    data.choices = data.choices.join(",");
    data.is_multiple = data.is_multiple ? 1 : 0;
    data.show_in_profile = data.show_in_profile ? 1 : 0;
    crateMutate(data);
  };

  const update = (data) => {
    data.choices = data.choices.join(",");
    data.is_multiple = data.is_multiple ? 1 : 0;
    data.show_in_profile = data.show_in_profile ? 1 : 0;
    updateMutate(data);
  };

  const showNewModal = () => {
    reset({});
    setShowCreateModal(true);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      ids: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.ids.push(selectItems[i].id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      ids: [data.id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editHandler = (data) => {
    if (data.choices) {
      data.choices = data.choices.split(",").map((d) => +d);
    }

    data.is_multiple = data.is_multiple === 1 ? true : false;
    data.show_in_profile = data.show_in_profile === 1 ? true : false;
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewModal} />}
          {permissions.delete && <Button disabled={!selectItems.length} label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} />}
        </div>
      </React.Fragment>
    );
  };

  const itemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.image} src={option?.image} />
        <div className="ml-2">{option.text}</div>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button disabled={!permissions.update} icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />
        <Button disabled={!permissions.delete} icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Quiz</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={quizData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Image found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="title" header="Title" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="show_in_profile" header="Show In Profile" body={(data) => (data.show_in_profile === 1 ? "YES" : "NO")} sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="is_multiple" header="Multiple" body={(data) => (data.is_multiple === 1 ? "YES" : "NO")} sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="type" header="Type" body={(data) => typeOption?.find((type) => type.value === data.type)?.label || "-"} sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="order" header="Order" sortable headerStyle={{ width: "8%", minWidth: "5rem" }}></Column>
              <Column field="created_at" body={(data) => NewTimeFormatter(data.created_at)} header="Created at" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header="Create Quiz" modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="text">Title</label>
            <InputTextarea placeholder="Input field" {...register("title")} id="text" type="text" />
          </div>
          <div className="field ">
            <label htmlFor="group_type">Group Type : </label>
            <Controller rules={{ required: true }} control={control} name="group_type" render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="group_type" value={field.value} onChange={(e) => field.onChange(e)} options={groupType} placeholder="Select Type" />} />
          </div>
          <div className="field">
            <label htmlFor="text">Question</label>
            <InputTextarea placeholder="Input field" {...register("question")} id="text" type="text" />
          </div>
          <div className="field">
            <label htmlFor="choices">Options : </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={[]}
                name="choices"
                render={({ field }) => (
                  <MultiSelect
                    filter
                    className="w-full"
                    itemTemplate={itemTemplate}
                    emptyFilterMessage="no data found"
                    disabled={quizOptionDataIsLoading}
                    value={field.value}
                    options={quizOptionData}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="text"
                    optionValue="id"
                    placeholder="Select choices"
                    display="chip"
                  />
                )}
              />
            </div>
          </div>
          <div className="field ">
            <label htmlFor="type">Card Type : </label>
            <Controller rules={{ required: true }} control={control} name="type" render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="type" value={field.value} onChange={(e) => field.onChange(e)} options={typeOption} placeholder="Select Type" />} />
          </div>
          <div className="field ">
            <label htmlFor="input_type">Input Type (in Beauty Profile) : </label>
            <Controller rules={{ required: true }} control={control} name="input_type" render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="input_type" value={field.value} onChange={(e) => field.onChange(e)} options={typeOption} placeholder="Select Type" />} />
          </div>
          <div className="field">
            <label className="block" htmlFor="show_in_profile">
              Show in User Profile
            </label>
            <Controller control={control} name="show_in_profile" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          <div className="field">
            <label className="block" htmlFor="is_multiple">
              Multiple Choice
            </label>
            <Controller control={control} name="is_multiple" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          {create_is_multiple_choice_watch ? (
            <div className="field">
              <label htmlFor="max_choice">Max Choice :</label>
              <Controller
                defaultValue={1}
                control={control}
                name="max_choice"
                render={({ field }) => <InputNumber disabled={!create_is_multiple_choice_watch} min={1} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
              />
            </div>
          ) : null}
          <div className="field">
            <label htmlFor="order">Order :</label>
            <Controller defaultValue="" control={control} name="order" render={({ field }) => <InputNumber min={0} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Edit Quiz" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="text">Title</label>
            <InputTextarea placeholder="Input field" {...updateForm.register("title")} id="text" type="text" />
          </div>
          <div className="field">
            <label htmlFor="text">Question</label>
            <InputTextarea placeholder="Input field" {...updateForm.register("question")} id="text" type="text" />
          </div>
          <div className="field ">
            <label htmlFor="group_type">Group Type : </label>
            <Controller rules={{ required: true }} control={updateForm.control} name="group_type" render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="group_type" value={field.value} onChange={(e) => field.onChange(e)} options={groupType} placeholder="Select Type" />} />
          </div>
          <div className="field">
            <label htmlFor="choices">Options : </label>
            <div className="w-full">
              <Controller
                control={updateForm.control}
                defaultValue={[]}
                name="choices"
                render={({ field }) => (
                  <MultiSelect
                    filter
                    className="w-full"
                    itemTemplate={itemTemplate}
                    emptyFilterMessage="no data found"
                    disabled={quizOptionDataIsLoading}
                    value={field.value}
                    options={quizOptionData}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="text"
                    optionValue="id"
                    placeholder="Select choices"
                    display="chip"
                  />
                )}
              />
            </div>
          </div>
          <div className="field ">
            <label htmlFor="type">Card Type : </label>
            <Controller rules={{ required: true }} control={updateForm.control} name="type" render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="type" value={field.value} onChange={(e) => field.onChange(e)} options={typeOption} placeholder="Select Type" />} />
          </div>
          <div className="field ">
            <label htmlFor="input_type">Input Type (in Beauty Profile) : </label>
            <Controller rules={{ required: true }} control={updateForm.control} name="input_type" render={({ field }) => <Dropdown onBlur={field.onBlur} ref={field.ref} id="input_type" value={field.value} onChange={(e) => field.onChange(e)} options={typeOption} placeholder="Select Type" />} />
          </div>
          <div className="field">
            <label className="block" htmlFor="show_in_profile">
              Show in User Profile
            </label>
            <Controller control={updateForm.control} name="show_in_profile" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          <div className="field">
            <label className="block" htmlFor="is_multiple">
              Multiple Choice
            </label>
            <Controller control={updateForm.control} name="is_multiple" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
          {update_is_multiple_choice_watch ? (
            <div className="field">
              <label htmlFor="max_choice">Max Choice :</label>
              <Controller
                defaultValue={1}
                control={updateForm.control}
                name="max_choice"
                render={({ field }) => <InputNumber disabled={!update_is_multiple_choice_watch} min={1} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
              />
            </div>
          ) : null}
          <div className="field">
            <label htmlFor="order">Order :</label>
            <Controller defaultValue="" control={updateForm.control} name="order" render={({ field }) => <InputNumber min={0} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Item?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Quiz, comparisonFn);
