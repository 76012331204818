// hooks
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useGetBlog } from "../../hook/blog.hook";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// api related
import Blog from "../../api/Blog";

// utils
import TimeFormatter from "../../utils/TimeFormatter";
import ActiveStatus from "../../utils/activeStatus";
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import Api from "../../api/Api";
import classNames from "classnames";

const Blogs = ({ permissions }) => {
  let navigate = useNavigate();
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [blogs, setBlogs] = useState(false);

  // api calling
  const { data, isLoading, isError: isBlogError, refetch } = useGetBlog();

  // query
  const { isLoading: isLoadingSetAsBanner, mutate: setAsBanner } = useMutation(async (data) => await Api().post("/blog/update-banner", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        toast.success("Blog Set As Highlight!", { duration: 4000 });
      } else {
        refetch();
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Blog.Delete(data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        refetch();
        setSelectItems([]);
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Blog Deleted!", { duration: 4000 });
      } else {
        refetch();
        setSelectItems([]);
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      ids: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.ids.push(selectItems[i].id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      ids: [data.id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  // life cycle
  useEffect(() => {
    if (!isLoading) {
      let temp_data = [];
      for (let i = 0; i < data.length; i++) {
        temp_data.push({ ...data[i], created_at: TimeFormatter(data[i].created_at) });
      }
      setBlogs(temp_data);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (isBlogError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isBlogError]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => navigate("/dashboard/blog/create")} />}
          {permissions.delete && <Button disabled={!selectItems.length} label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && (
          <Button
            loading={isLoadingSetAsBanner}
            icon={
              <i
                className={classNames(
                  {
                    "rotate-90": rowData.as_banner === 1,
                  },
                  "fas fa fa-solid fa-thumbtack"
                )}
              ></i>
            }
            className="p-button-rounded p-button-success mr-2"
            onClick={() => {
              setAsBanner({
                id: rowData.id,
                as_banner: rowData.as_banner === 1 ? 0 : 1,
              });
            }}
          />
        )}
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => navigate(`/dashboard/blog/update/${rowData.id}`, { replace: true })} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    let status = rowData.active_status ? "instock" : "outofstock";
    return <span className={`product-badge status-${status}`}>{ActiveStatus(rowData.active_status)}</span>;
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Blogs</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <>
        <img src={rowData.thumbnail} alt={rowData.title} className="shadow-2" width="100" />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={blogs}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Blog found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="a" header="Image" body={imageBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="title" header="Title" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column body={statusBodyTemplate} header="Status" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="order" header="Order" sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="as_banner" body={(d) => (d.as_banner === 1 ? "YES" : "NO")} header="Highlight" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              <Column field="created_at" header="Created at" body={(data) => NewTimeFormatter(data.created_at)} sortable headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "15%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected categories?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Blogs, comparisonFn);
