// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import toast from "react-hot-toast";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

// utils
import classNames from "classnames";
import basicJodithConfig from "../../config/basicJodithConfig";

// api related
import Api from "../../api/Api";
import { Image } from "primereact/image";
import { useGetBlog } from "../../hook/blog.hook";
import { useEffect } from "react";
import JoditEditor from "jodit-react";

const HomePageSetting = () => {
  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  // query
  const { data: blogOptions } = useGetBlog();
  const { data: productOption } = useQuery("product", () => getProduct());
  const { data: homeImagesOptions } = useQuery("home-image", () => getHomeImages());
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        getHomePageDetails();
        toast.success("Home Updated!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getProduct = async () => {
    try {
      const res = await Api().get("/product?page=1&limit=999999");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getHomeImages = async () => {
    try {
      const res = await Api().get("/home-image?page=1&limit=999999&type=-1");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getHomePageDetails = async () => {
    try {
      const response = await Api().get("/", {
        headers: {
          organization: process.env.REACT_APP_ORGANIZATION_ID,
        },
      });

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }
      let details = response.data.data;
      delete details.image_banner1;
      delete details.image_banner2;
      delete details.image_section3;
      delete details.image_section4;
      delete details.products_section1;
      delete details.products_section2;
      delete details.blogs_section5;

      details.banner1 = details.banner1.split(",").map((d) => +d)[0];
      details.banner2 = details.banner2.split(",").map((d) => +d)[0];
      details.section1_products = details.section1_products.split(",").map((d) => +d);
      details.section2_product = +details.section2_product;
      details.section3_image = +details.section3_image;
      details.section4_images = details.section4_images.split(",").map((d) => +d);
      details.section5_blogs = details.section5_blogs.split(",").map((d) => +d);
      details.slide = details.slide?.split(",").map((d) => +d);

      for (var key in details) {
        if (!details[key]) {
          delete details[key];
        }
      }

      reset(details);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmit = (data) => {
    data.section1_products = data.section1_products?.join(",") || "";
    data.section4_images = data.section4_images?.join(",") || "";
    data.section5_blogs = data.section5_blogs?.join(",") || "";
    data.slide = data.slide.join(",");

    // remove undifined inside data
    Object.keys(data).forEach((key) => {
      const isUndifined = data[key] === undefined || data[key] === null;
      if (isUndifined) {
        delete data[key];
      }
    });

    crateMutate(data);
  };

  // components
  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.images[0]?.url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };
  const imageItemOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <Image width={50} alt={option?.id || ""} src={option?.image} />
      </div>
    );
  };
  const imageItemValueTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <Image preview width={30} alt={option?.id || ""} src={option?.image} />
      </div>
    );
  };
  const imageItemValueMultipleTemplate = (option) => {
    const search_selected_options = homeImagesOptions?.find((img) => img?.id === option);

    return (
      <div className="flex align-items-center">
        <Image height={40} alt={search_selected_options?.id || ""} src={search_selected_options?.image} preview />
      </div>
    );
  };
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Home Page Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    getHomePageDetails(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12">
          <label htmlFor="top_text">Top Text : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              name="top_text"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <h4>Banner</h4>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="banner1">First Banner : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="banner1"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  className={classNames({ "p-invalid": formState.errors?.banner1 }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!homeImagesOptions?.length}
                  value={field.value}
                  // its mean image type only banner type
                  options={homeImagesOptions?.filter((h) => h.type === 0)}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  itemTemplate={imageItemOptionTemplate}
                  valueTemplate={imageItemValueTemplate}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select banner"
                />
              )}
            />
            {formState.errors?.banner1 && (
              <small id="banner1" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="banner2">Second Banner : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="banner2"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  className={classNames({ "p-invalid": formState.errors?.banner2 }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!homeImagesOptions?.length}
                  value={field.value}
                  // its mean image type only banner type
                  options={homeImagesOptions?.filter((h) => h.type === 0)}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  itemTemplate={imageItemOptionTemplate}
                  valueTemplate={imageItemValueTemplate}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select banner"
                />
              )}
            />
            {formState.errors?.banner2 && (
              <small id="banner2" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="slide">Running Claims : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={[]}
              name="slide"
              render={({ field }) => (
                <MultiSelect
                  className="w-full custom-image-value-multiselect"
                  emptyFilterMessage="no data found"
                  disabled={!homeImagesOptions?.length}
                  value={field.value}
                  // its mean image type only icon type
                  options={homeImagesOptions?.filter((h) => h.type === 1)}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select image"
                  display="chip"
                  selectedItemTemplate={imageItemValueMultipleTemplate}
                  itemTemplate={imageItemOptionTemplate}
                />
              )}
            />
          </div>
        </div>

        {/* section one */}
        <React.Fragment>
          <div className="col-12">
            <h4>Section 1</h4>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section1_image">Image : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="section1_image"
                rules={{ required: true }}
                render={({ field }) => (
                  <Dropdown
                    className={classNames({ "p-invalid": formState.errors?.section1_image }, "w-full")}
                    emptyFilterMessage="no data found"
                    disabled={!homeImagesOptions?.length}
                    value={field.value}
                    // its mean image type only content type
                    options={homeImagesOptions?.filter((h) => h.type === 2)}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    itemTemplate={imageItemOptionTemplate}
                    valueTemplate={imageItemValueTemplate}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select banner"
                  />
                )}
              />
              {formState.errors?.section1_image && (
                <small id="section1_image" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section1_products">Products List : </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={[]}
                name="section1_products"
                render={({ field }) => (
                  <MultiSelect
                    filter
                    className="w-full"
                    emptyFilterMessage="no data found"
                    disabled={!productOption?.length}
                    value={field.value}
                    options={productOption}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select products"
                    display="chip"
                    itemTemplate={productSelectTemplate}
                  />
                )}
              />
              {formState.errors?.section1_products && (
                <small id="section1_products" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        </React.Fragment>

        {/* section two */}
        <React.Fragment>
          <div className="col-12">
            <h4>Section 2</h4>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section2_image">Image : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="section2_image"
                rules={{ required: true }}
                render={({ field }) => (
                  <Dropdown
                    className={classNames({ "p-invalid": formState.errors?.section2_image }, "w-full")}
                    emptyFilterMessage="no data found"
                    disabled={!homeImagesOptions?.length}
                    value={field.value}
                    // its mean image type only content type
                    options={homeImagesOptions?.filter((h) => h.type === 2)}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    itemTemplate={imageItemOptionTemplate}
                    valueTemplate={imageItemValueTemplate}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select banner"
                  />
                )}
              />
              {formState.errors?.section2_image && (
                <small id="section2_image" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section2_product">Products List : </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={[]}
                name="section2_product"
                render={({ field }) => (
                  <Dropdown
                    selectionLimit={1}
                    filter
                    className="w-full"
                    emptyFilterMessage="no data found"
                    disabled={!productOption?.length}
                    value={field.value}
                    options={productOption}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select products"
                    itemTemplate={productSelectTemplate}
                  />
                )}
              />
              {formState.errors?.section2_product && (
                <small id="section2_product" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        </React.Fragment>

        {/* section three */}
        <React.Fragment>
          <div className="col-12">
            <h4>Section 3</h4>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section3_image">Image : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="section3_image"
                rules={{ required: true }}
                render={({ field }) => (
                  <Dropdown
                    className={classNames({ "p-invalid": formState.errors?.section3_image }, "w-full")}
                    emptyFilterMessage="no data found"
                    disabled={!homeImagesOptions?.length}
                    value={field.value}
                    // its mean image type only content type
                    options={homeImagesOptions?.filter((h) => h.type === 2)}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    itemTemplate={imageItemOptionTemplate}
                    valueTemplate={imageItemValueTemplate}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select image"
                  />
                )}
              />
              {formState.errors?.section3_image && (
                <small id="section3_image" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        </React.Fragment>

        {/* section four */}
        <React.Fragment>
          <div className="col-12">
            <h4>Section 4</h4>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section4_title">Title : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="section4_title"
                render={({ field }) => (
                  <InputText
                    placeholder="Input title"
                    value={field.value}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    onChange={(e) => field.onChange(e)}
                    id="section4_title"
                    type="text"
                    className={classNames(
                      {
                        "p-invalid": formState.errors?.section4_title,
                      },
                      "w-full"
                    )}
                  />
                )}
              />
              {formState.errors?.section4_title && (
                <small id="section4_title" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section4_images">Image : </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={[]}
                name="section4_images"
                render={({ field }) => (
                  <MultiSelect
                    className="w-full custom-image-value-multiselect"
                    emptyFilterMessage="no data found"
                    disabled={!homeImagesOptions?.length}
                    value={field.value}
                    // its mean image type only content type
                    options={homeImagesOptions?.filter((h) => h.type === 3)}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select image"
                    display="chip"
                    selectedItemTemplate={imageItemValueMultipleTemplate}
                    itemTemplate={imageItemOptionTemplate}
                  />
                )}
              />
            </div>
          </div>
        </React.Fragment>

        {/* section five */}
        <React.Fragment>
          <div className="col-12">
            <h4>Section 5</h4>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section5_title">Title : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="section5_title"
                render={({ field }) => (
                  <InputText
                    placeholder="Input title"
                    value={field.value}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    onChange={(e) => field.onChange(e)}
                    id="section5_title"
                    type="text"
                    className={classNames(
                      {
                        "p-invalid": formState.errors?.section5_title,
                      },
                      "w-full"
                    )}
                  />
                )}
              />
              {formState.errors?.section5_title && (
                <small id="section5_title" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section5_button_name">Button Name : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="section5_button_name"
                render={({ field }) => (
                  <InputText
                    placeholder="Input button name"
                    value={field.value}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    onChange={(e) => field.onChange(e)}
                    id="section5_button_name"
                    type="text"
                    className={classNames(
                      {
                        "p-invalid": formState.errors?.section5_button_name,
                      },
                      "w-full"
                    )}
                  />
                )}
              />
              {formState.errors?.section5_button_name && (
                <small id="section5_button_name" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section5_button_route">Button Route : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="section5_button_route"
                render={({ field }) => (
                  <InputText
                    placeholder="Input button route"
                    value={field.value}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    onChange={(e) => field.onChange(e)}
                    id="section5_button_route"
                    type="text"
                    className={classNames(
                      {
                        "p-invalid": formState.errors?.section5_button_route,
                      },
                      "w-full"
                    )}
                  />
                )}
              />
              {formState.errors?.section5_button_route && (
                <small id="section5_button_route" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section5_blogs">Blog List : </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={[]}
                name="section5_blogs"
                render={({ field }) => (
                  <MultiSelect filter className="w-full" emptyFilterMessage="no data found" disabled={!blogOptions?.length} value={field.value} options={blogOptions} onChange={(e) => field.onChange(e)} optionLabel="title" optionValue="id" placeholder="Select blogs" display="chip" />
                )}
              />
              {formState.errors?.section5_blogs && (
                <small id="section5_blogs" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        </React.Fragment>

        {/* section six */}
        <React.Fragment>
          <div className="col-12">
            <h4>Section 6</h4>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section6_title">Title : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="section6_title"
                render={({ field }) => (
                  <InputText
                    placeholder="Input title"
                    value={field.value}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    onChange={(e) => field.onChange(e)}
                    id="section6_title"
                    type="text"
                    className={classNames(
                      {
                        "p-invalid": formState.errors?.section6_title,
                      },
                      "w-full"
                    )}
                  />
                )}
              />
              {formState.errors?.section6_title && (
                <small id="section6_title" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        </React.Fragment>

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(HomePageSetting, comparisonFn);
