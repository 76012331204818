// hooks
import React from "react";
import { Controller, useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import { useMutation } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import JoditEditor from "jodit-react";
import JodithJournalConfig from "../../config/JodithJournalConfig";

// config
import Api from "../../api/Api";

const StaticPageCreate = () => {
  const navigate = useNavigate();

  // query
  const { isLoading: loadingCreate, mutate: create } = useMutation(async (data) => await Api().post("static", data), {
    onSettled: (response, error) => {
      if (response.data.status === 200) {
        navigate("/dashboard/static-page");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // functions
  const onSubmit = (data) => {
    const formData = new FormData();

    // remove undifined inside data
    Object.keys(data).forEach((key) => {
      const isUndifined = data[key] === undefined;
      if (isUndifined) {
        delete data[key];
      }
    });

    Object.keys(data).forEach((key) => {
      let isArray = Array.isArray(data[key]);
      // if array loop with same key
      if (isArray) {
        for (let i = 0; i < data[key].length; i++) {
          let item = data[key];
          formData.append(key, item[i]);
        }
      } else {
        formData.append(key, data[key]);
      }
    });

    create(formData);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Static Page
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <Link to="/dashboard/static-page">
          <Button type="button" label="Back" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card" style={{ borderRadius: 0 }}>
        <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
        <div className="p-fluid formgrid grid ">
          <div className="field col-12">
            <label htmlFor="title">Title :</label>
            <Controller
              defaultValue={""}
              rules={{ required: true }}
              control={control}
              name="title"
              render={({ field }) => <InputText id="title" placeholder="Input title" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={errors.title && "p-invalid"} />}
            />
          </div>

          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Body Content : {errors.content && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="content"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={JodithJournalConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex justify-content-center mt-4 w-full">
            <div className="flex">
              <Button loading={loadingCreate} label="Save" className=" p-button-primary mr-4" />
              <Link to="/dashboard/static-page">
                <Button type="button" label="Back" className=" p-button-secondary" />
              </Link>
            </div>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(StaticPageCreate, comparisonFn);
