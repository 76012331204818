import Api from "./Api";

export default {
  Get() {
    return Api().get("/blog?page=1&limit=9999999&sort_by=order&sort_type=ASC");
  },
  Update(data) {
    return Api().post(`/blog/edit`, data);
  },
  Details(id) {
    return Api().get(`/blog/detail/${id}`);
  },
  Add(data) {
    return Api().post(`/blog`, data);
  },
  Delete(data) {
    return Api().delete(`/blog`, { data: data });
  },
};
