// hooks
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useEffect } from "react";

// conponents
import toast from "react-hot-toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { Image } from "primereact/image";
import ReactImageUploading from "react-images-uploading";
import JoditEditor from "jodit-react";

// utils
import basicJodithConfig from "../../config/basicJodithConfig";

// api related
import Api from "../../api/Api";

const AboutPageSetting = () => {
  // hooks
  const { control, handleSubmit, formState, reset } = useForm();
  const [status, setStatus] = useState("create");
  const [images1, setImages1] = useState([]);
  const [images2, setImages2] = useState([]);
  const [images3, setImages3] = useState([]);
  const [images4, setImages4] = useState([]);

  // query
  const { data: homeImagesOptions } = useQuery("home-image", () => getHomeImages());
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/about", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        getAboutPageDetails();
        toast.success("About Created!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/about/edit", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        getAboutPageDetails();
        toast.success("About Updated!");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getHomeImages = async () => {
    try {
      const res = await Api().get("/home-image?page=1&limit=999999&type=-1");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getAboutPageDetails = async () => {
    try {
      const response = await Api().get("/about");

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      if (!response.data.data) {
        setStatus("create");
        return;
      } else {
        setStatus("update");
      }

      let details = response.data.data;

      details.slide = details?.slide?.split(",")?.map((d) => +d);
      details.section5_image = details?.section5_image?.split(",")?.map((d) => +d);

      let temp_image_1 = [];
      let temp_image_2 = [];
      let temp_image_3 = [];
      let temp_image_4 = [];

      if (details.banner) {
        // id random
        temp_image_1.push({ id: 1, data_url: details.banner, file: {} });
      }

      if (details.banner_mobile) {
        // id random
        temp_image_2.push({ id: 1, data_url: details.banner_mobile, file: {} });
      }

      if (details.section2_image) {
        // id random
        temp_image_3.push({ id: 1, data_url: details.section2_image, file: {} });
      }

      if (details.section3_image) {
        // id random
        temp_image_4.push({ id: 1, data_url: details.section3_image, file: {} });
      }

      setImages1(temp_image_1);
      setImages2(temp_image_2);
      setImages3(temp_image_3);
      setImages4(temp_image_4);

      reset(details);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();

    if (status === "create") {
      if (images1) {
        formData.append("files", images1[0].file);
      }
      if (images2) {
        formData.append("files_mobile", images2[0].file);
      }
      if (images3) {
        formData.append("files_section2", images3[0].file);
      }
      if (images4) {
        formData.append("files_section3", images4[0].file);
      }
    }

    if (status === "update") {
      formData.append("id", data.id);

      if (images1.length) {
        formData.append("files", images1[0].file);
      } else {
        formData.append("banner", data.banner);
      }
      if (images2.length) {
        formData.append("files_mobile", images2[0].file);
      } else {
        formData.append("banner_mobile", data.banner_mobile);
      }
      if (images3.length) {
        formData.append("files_section2", images3[0].file);
      } else {
        formData.append("section2_image", data.section2_image);
      }
      if (images4.length) {
        formData.append("files_section3", images4[0].file);
      } else {
        formData.append("section3_image", data.section3_image);
      }
    }

    data.section5_image = data.section5_image.join(",");
    data.slide = data.slide.join(",");
    formData.append("slide", data.slide);
    formData.append("section1", data.section1);
    formData.append("section2", data.section2);
    formData.append("section3", data.section3);
    formData.append("section4", data.section4);
    formData.append("section5", data.section5);
    formData.append("section5_image", data.section5_image);

    if (status === "create") {
      crateMutate(formData);
    }

    if (status === "update") {
      updateMutate(formData);
    }
  };

  const onChangeImage1 = (imageList) => {
    setImages1(imageList);
  };
  const onChangeImage2 = (imageList) => {
    setImages2(imageList);
  };
  const onChangeImage3 = (imageList) => {
    setImages3(imageList);
  };
  const onChangeImage4 = (imageList) => {
    setImages4(imageList);
  };

  const hanldeErrorImageUpload = (error) => {
    if (error?.acceptType) {
      toast.error("format image should jpg, jpeg, png, gif, webp");
    }

    if (error?.maxFileSize) {
      toast.error("File Size Max 1MB");
    }
    if (error?.maxNumber) {
      toast.error("max file is 8 images");
    }
  };

  // components
  const imageItemOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <Image width={50} alt={option?.id || ""} src={option?.image} />
      </div>
    );
  };

  const imageItemValueMultipleTemplate = (option) => {
    const search_selected_options = homeImagesOptions?.find((img) => img?.id === option);

    return (
      <div className="flex align-items-center">
        <Image height={40} alt={search_selected_options?.id || ""} src={search_selected_options?.image} preview />
      </div>
    );
  };
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Home Page Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    getAboutPageDetails(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>

        <React.Fragment>
          <div className="col-12">
            <h4>Banner</h4>
          </div>
          <section style={{ borderRadius: 0 }} className="field col-12 ">
            <label htmlFor="">Upload Images Max 1MB / Image (desktop): </label>
            <ReactImageUploading style={{ width: "100%" }} maxNumber={8} maxFileSize={1000000} onError={hanldeErrorImageUpload} value={images1} onChange={onChangeImage1} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                  <div className="flex justify-content-center w-full">
                    <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                  </div>

                  {imageList.length ? (
                    <div className="grid mt-4 w-full">
                      {imageList.map((image, index) => (
                        <div key={index} className="col-4 lg:col-2 relative">
                          <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                          <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                          <div className="flex" style={{ gap: "10px" }}>
                            <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </ReactImageUploading>
          </section>
          <section style={{ borderRadius: 0 }} className="field col-12 ">
            <label htmlFor="">Upload Images Max 1MB / Image (mobile): </label>
            <ReactImageUploading style={{ width: "100%" }} maxNumber={8} maxFileSize={1000000} onError={hanldeErrorImageUpload} value={images2} onChange={onChangeImage2} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                  <div className="flex justify-content-center w-full">
                    <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                  </div>

                  {imageList.length ? (
                    <div className="grid mt-4 w-full">
                      {imageList.map((image, index) => (
                        <div key={index} className="col-4 lg:col-2 relative">
                          <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                          <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                          <div className="flex" style={{ gap: "10px" }}>
                            <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </ReactImageUploading>
          </section>
          <div className="field col-12 lg:col-12">
            <label htmlFor="slide">Running Claims : </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={[]}
                name="slide"
                render={({ field }) => (
                  <MultiSelect
                    className="w-full custom-image-value-multiselect"
                    emptyFilterMessage="no data found"
                    disabled={!homeImagesOptions?.length}
                    value={field.value}
                    // its mean image type only icon type
                    options={homeImagesOptions?.filter((h) => h.type === 1)}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select image"
                    display="chip"
                    selectedItemTemplate={imageItemValueMultipleTemplate}
                    itemTemplate={imageItemOptionTemplate}
                  />
                )}
              />
            </div>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className="col-12">
            <h4>Section 1</h4>
          </div>
          <div className="field col-12">
            <label htmlFor="section1">Section 1 Text : </label>
            <div className="w-full">
              <Controller
                rules={{ required: false }}
                control={control}
                name="section1"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={basicJodithConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              {formState.errors?.section1 && (
                <small id="section1" className="p-error block pt-1">
                  filed required
                </small>
              )}
            </div>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className="col-12">
            <h4>Section 2</h4>
          </div>
          <div className="field col-12">
            <label htmlFor="section2">Section 2 Text : </label>
            <div className="w-full">
              <Controller
                rules={{ required: false }}
                control={control}
                name="section2"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={basicJodithConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              {formState.errors?.section2 && (
                <small id="section2" className="p-error block pt-1">
                  filed required
                </small>
              )}
            </div>
          </div>
          <section style={{ borderRadius: 0 }} className="field col-12 ">
            <label htmlFor="">Upload Images Max 1MB / Image : </label>
            <ReactImageUploading style={{ width: "100%" }} maxNumber={8} maxFileSize={1000000} onError={hanldeErrorImageUpload} value={images3} onChange={onChangeImage3} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                  <div className="flex justify-content-center w-full">
                    <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                  </div>

                  {imageList.length ? (
                    <div className="grid mt-4 w-full">
                      {imageList.map((image, index) => (
                        <div key={index} className="col-4 lg:col-2 relative">
                          <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                          <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                          <div className="flex" style={{ gap: "10px" }}>
                            <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </ReactImageUploading>
          </section>
        </React.Fragment>

        <React.Fragment>
          <div className="col-12">
            <h4>Section 3</h4>
          </div>
          <div className="field col-12">
            <label htmlFor="section3">Section 3 Text : </label>
            <div className="w-full">
              <Controller
                rules={{ required: false }}
                control={control}
                name="section3"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={basicJodithConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              {formState.errors?.section3 && (
                <small id="section3" className="p-error block pt-1">
                  filed required
                </small>
              )}
            </div>
          </div>
          <section style={{ borderRadius: 0 }} className="field col-12 ">
            <label htmlFor="">Upload Images Max 1MB / Image : </label>
            <ReactImageUploading style={{ width: "100%" }} maxNumber={8} maxFileSize={1000000} onError={hanldeErrorImageUpload} value={images4} onChange={onChangeImage4} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                  <div className="flex justify-content-center w-full">
                    <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                  </div>

                  {imageList.length ? (
                    <div className="grid mt-4 w-full">
                      {imageList.map((image, index) => (
                        <div key={index} className="col-4 lg:col-2 relative">
                          <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                          <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                          <div className="flex" style={{ gap: "10px" }}>
                            <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </ReactImageUploading>
          </section>
        </React.Fragment>

        <React.Fragment>
          <div className="col-12">
            <h4>Section 4</h4>
          </div>
          <div className="field col-12">
            <label htmlFor="section4">Section 4 Text : </label>
            <div className="w-full">
              <Controller
                rules={{ required: false }}
                control={control}
                name="section4"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={basicJodithConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              {formState.errors?.section4 && (
                <small id="section4" className="p-error block pt-1">
                  filed required
                </small>
              )}
            </div>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className="col-12">
            <h4>Section 5</h4>
          </div>
          <div className="field col-12">
            <label htmlFor="section5">Section 5 Text : </label>
            <div className="w-full">
              <Controller
                rules={{ required: false }}
                control={control}
                name="section5"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={basicJodithConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              {formState.errors?.section5 && (
                <small id="section5" className="p-error block pt-1">
                  filed required
                </small>
              )}
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="section5_image">About Card : </label>
            <div className="w-full">
              <Controller
                control={control}
                defaultValue={[]}
                name="section5_image"
                render={({ field }) => (
                  <MultiSelect
                    className="w-full custom-image-value-multiselect"
                    emptyFilterMessage="no data found"
                    disabled={!homeImagesOptions?.length}
                    value={field.value}
                    // its mean image type only about card type
                    options={homeImagesOptions?.filter((h) => h.type === 4)}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select image"
                    display="chip"
                    selectedItemTemplate={imageItemValueMultipleTemplate}
                    itemTemplate={imageItemOptionTemplate}
                  />
                )}
              />
            </div>
          </div>
        </React.Fragment>

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading || updateLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(AboutPageSetting, comparisonFn);
