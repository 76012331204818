// hooks
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import { useMutation } from "react-query";
import { useGetBlog, useGetBlogCategories } from "../../hook/blog.hook";
import { useGetProducts } from "../../hook/product.hook";

// conponents
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import JoditEditor from "jodit-react";
import JodithJournalConfig from "../../config/JodithJournalConfig";

// config
import Blog from "../../api/Blog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import ReactImageUploading from "react-images-uploading";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";

const CreateBlog = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [imageThumb, setImageThumb] = useState([]);

  // api calling
  const { data: productsData, isLoading: loadingProducts, isError: isProductsError } = useGetProducts();
  const { data: categoriesData, isLoading: loadingCategories, isError: isCategoriesError } = useGetBlogCategories();
  const { data: blogsData, isLoading: loadingBlogs, isError: isBlogsError } = useGetBlog();

  // query
  const { isLoading: loadingCreate, mutate: createBlog } = useMutation(async (data) => await Blog.Add(data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status === 200) {
          navigate("/dashboard/blog");
        } else {
          toast.error(response.data.message, { duration: 5000 });
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // state
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);

  // functions
  const onSubmit = (data) => {
    data.product_ids = data?.product_ids?.join(",") || "";
    data.related = data?.related?.join(",") || "";
    data.active_status = data.active_status ? 1 : 0;

    const formData = new FormData();

    if (!images.length) return toast.error("Please Upload Images");
    if (images.length) {
      let temp_image = [];
      for (let i = 0; i < images.length; i++) {
        temp_image.push(images[i].file);
      }
      data.files = temp_image[0];
    }

    if (imageThumb.length) {
      let temp_image = [];
      for (let i = 0; i < imageThumb.length; i++) {
        temp_image.push(imageThumb[i].file);
      }
      data.files_thumbnail = temp_image[0];
    }

    // remove undifined inside data
    Object.keys(data).forEach((key) => {
      const isUndifined = data[key] === undefined;
      if (isUndifined) {
        delete data[key];
      }
    });

    Object.keys(data).forEach((key) => {
      let isArray = Array.isArray(data[key]);
      // if array loop with same key
      if (isArray) {
        for (let i = 0; i < data[key].length; i++) {
          let item = data[key];
          formData.append(key, item[i]);
        }
      } else {
        formData.append(key, data[key]);
      }
    });

    createBlog(formData);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create blog
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const rightToolbar = () => {
    return (
      <React.Fragment>
        <Link to="/dashboard/blog">
          <Button type="button" label="Back" className="p-button-outlined p-button-secondary ml-4" />
        </Link>
      </React.Fragment>
    );
  };

  const onChangeImage = (imageList) => {
    setImages(imageList);
  };

  const hanldeErrorImageUpload = (error) => {
    if (error?.acceptType) {
      toast.error("format image should jpg, jpeg, png, gif, webp");
    }

    if (error?.maxFileSize) {
      toast.error("File Size Max 1MB");
    }
    if (error?.maxNumber) {
      toast.error("max file is 1 image");
    }
  };

  const onChangeImageThumb = (imageList) => {
    setImageThumb(imageList);
  };
  // life cycle
  useEffect(() => {
    if (!loadingBlogs) {
      let temp_data = [];
      for (let i = 0; i < blogsData.length; i++) {
        temp_data.push({ name: blogsData[i].title, value: blogsData[i].id });
      }
      setBlogs(temp_data);
    }
  }, [loadingBlogs, blogsData]);

  useEffect(() => {
    if (!loadingProducts) {
      let temp_data = [];

      for (let i = 0; i < productsData.length; i++) {
        temp_data.push({ name: productsData[i].name, value: productsData[i].id });
      }

      setProducts(temp_data);
    }
  }, [loadingProducts, productsData]);

  useEffect(() => {
    if (!loadingCategories) {
      let temp_data = [];
      for (let i = 0; i < categoriesData.length; i++) {
        temp_data.push({ name: categoriesData[i].name, value: categoriesData[i].id });
      }
      setCategories(temp_data);
    }
  }, [loadingCategories, categoriesData]);

  useEffect(() => {
    if (isProductsError || isCategoriesError || isBlogsError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isProductsError, isBlogsError, isCategoriesError]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card" style={{ borderRadius: 0 }}>
        <Toolbar className="mb-4" left={leftToolbar} right={rightToolbar} />
        <div className="p-fluid formgrid grid ">
          <div className="field col-12 md:col-6">
            <label htmlFor="title">Title :</label>
            <Controller
              defaultValue={""}
              rules={{ required: true }}
              control={control}
              name="title"
              render={({ field }) => <InputText id="title" placeholder="Input title" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={errors.title && "p-invalid"} />}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="category_id">Category : </label>
            <Controller
              rules={{ required: true }}
              control={control}
              name="category_id"
              render={({ field }) => (
                <Dropdown className={errors.category_id && "p-invalid"} onBlur={field.onBlur} inputRef={field.ref} id="category_id" value={field.value} onChange={(e) => field.onChange(e)} disabled={loadingCategories} options={categories} optionLabel="name" placeholder="Select category" />
              )}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="related">Related Blog : </label>
            <Controller
              rules={{ required: false }}
              control={control}
              name="related"
              render={({ field }) => (
                <MultiSelect
                  onBlur={field.onBlur}
                  inputRef={field.ref}
                  id="related"
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  disabled={!blogs.length}
                  options={blogs}
                  optionLabel="name"
                  placeholder={!loadingBlogs && !blogs.length ? "No data available" : "Select related blog (optional)"}
                />
              )}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="product_ids">Related Product : </label>
            <Controller
              rules={{ required: false }}
              control={control}
              name="product_ids"
              render={({ field }) => <MultiSelect disabled={loadingProducts} onBlur={field.onBlur} inputRef={field.ref} id="product_ids" value={field.value} onChange={(e) => field.onChange(e)} options={products} optionLabel="name" placeholder="Select product (optional)" />}
            />
          </div>
          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Short Description : {errors.short_description && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="short_description"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={JodithJournalConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Body Content : {errors.description && <span style={{ color: "#D9362B" }}>Content Required</span>}</label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="description"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={JodithJournalConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="title">Meta Title :</label>
            <Controller
              defaultValue={""}
              rules={{ required: true }}
              control={control}
              name="meta_title"
              render={({ field }) => <InputText id="meta_title" placeholder="Input meta title" onBlur={field.onBlur} onChange={(e) => field.onChange(e)} type="text" value={field.value} className={errors.meta_title && "p-invalid"} />}
            />
          </div>
          <div className="field col-12 md:col-12">
            <div className="field p-fluid">
              <label>Meta Description : {errors.meta_description && <span style={{ color: "#D9362B" }}>Meta Description Required</span>}</label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="meta_description"
                defaultValue={""}
                render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={errors.meta_description && "p-invalid"} placeholder="Write Meta Descriptions" autoResize rows="10" cols="30" />}
              />
            </div>
          </div>
          <div className="field col-12">
            <label htmlFor="meta_title">Active Status : </label>
            <div className="w-full">
              <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
            </div>
          </div>
          <section style={{ borderRadius: 0 }} className="field col-12 ">
            <label htmlFor="">Upload Images Max 1MB : </label>
            <ReactImageUploading style={{ width: "100%" }} maxNumber={1} maxFileSize={1000000} onError={hanldeErrorImageUpload} multiple value={images} onChange={onChangeImage} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                  <div className="flex justify-content-center">
                    <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                  </div>

                  {imageList.length ? (
                    <div className="grid mt-4 w-full">
                      {imageList.map((image, index) => (
                        <div key={index} className="col-4 lg:col-2 relative">
                          <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                          <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                          <div className="flex" style={{ gap: "10px" }}>
                            <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </ReactImageUploading>
          </section>
          <section style={{ borderRadius: 0 }} className="field col-12 ">
            <label htmlFor="">Thumbnail Image Max 1MB: </label>
            <ReactImageUploading style={{ width: "100%" }} maxNumber={1} multiple value={imageThumb} onError={hanldeErrorImageUpload} onChange={onChangeImageThumb} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg", "gif", "webp"]}>
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
                <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                  <div className="flex justify-content-center">
                    <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                  </div>

                  {imageList.length ? (
                    <div className="grid mt-4 w-full">
                      {imageList.map((image, index) => (
                        <div key={index} className="col-4 lg:col-2 relative">
                          <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                          <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                          <div className="flex" style={{ gap: "10px" }}>
                            <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </ReactImageUploading>
          </section>
          <div className="field col-12">
            <label htmlFor="order">Order : </label>
            <Controller
              defaultValue={0}
              control={control}
              name="order"
              render={({ field }) => <InputNumber className={classNames({ "p-invalid": errors?.order }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
            />
            {errors?.order && (
              <small id="order" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>

          <div className="flex justify-content-center mt-4 w-full">
            <div className="flex">
              <Button loading={loadingCreate} label="Save" className=" p-button-primary mr-4" />
              <Link to="/dashboard/blog">
                <Button type="button" label="Back" className=" p-button-secondary" />
              </Link>
            </div>
          </div>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(CreateBlog, comparisonFn);
